<template>
  <v-card class="user-creation-card" elevation="3">
    <v-card-title class="headline primary white--text">
      Create New User
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newItem.displayName"
                label="Display Name"
                :rules="displayNameRules"
                required
                outlined
                dense
                prepend-inner-icon="mdi-account"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newItem.email"
                label="Email"
                :rules="emailRules"
                required
                outlined
                dense
                prepend-inner-icon="mdi-email"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-divider class="my-4"></v-divider>

          <h3 class="subtitle-1 font-weight-bold mb-2">Apps Access Level</h3>
          <v-row v-if="newItem.customClaims">
            <v-col cols="12" sm="6" md="4" v-for="(app, index) in apps" :key="index">
              <v-checkbox
                v-model="newItem.customClaims.appLevel"
                :label="app.name"
                :value="index"
                hide-details
                class="mb-2"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-divider class="my-4"></v-divider>

          <h3 class="subtitle-1 font-weight-bold mb-2">Role Configuration</h3>
          <v-row v-if="newItem.customClaims?.roles">
            <v-col cols="12" v-for="(r, i) in newItem.customClaims.roles" :key="i">
              <v-card outlined class="pa-3 mb-3">
                <p>
                  {{r.name }}
                </p>
                <v-row>
                  
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-model="r.appId"
                      label="App ID"
                      disabled
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-select
                      :items='["Super_Admin", "Admin", "Program_Admin", "User"]'
                      v-model="r.role"
                      label="Role"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-model="r.title"
                      label="Title"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-model="r.org"
                      label="Organization"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="create"
        x-large
        :disabled="!valid"
        class="px-8"
      >
        Create User
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
  
  <script>
  import { createUser } from '../dataservices/adminDataService';
  
  export default {
    data() {
      return {
        apps: [
      { name: 'Admin App', value: 0 },
      { name: 'OPDCS', value: 1 },
      { name: 'CATTS', value: 2 },
      { name: 'SPAR', value: 3 },
      { name: 'NGRI', value: 4 },
      { name: '71.09', value: 5 }
    ],
        valid : true,
        newItem: {
          email: '',
          displayName: '',
          customClaims: {
            appLevel: [],
            roles: [
              {
                name : "Admin App",
                role: 'User',
                title: null,
                org: null,
                appId: 0,
              },
              {
                name : "OPDCS",
                role: 'User',
                title: null,
                org: null,
                appId: 1,
              },
              {
                name : "CATTS",
                role: 'User',
                title: null,
                org: null,
                appId: 2,
              },
              {
                name : "SPAR",
                role: 'User',
                title: null,
                org: null,
                appId: 3,
              },
              {
                name : "NGRI",
                role: 'User',
                title: null,
                org: null,
                appId: 4,
              },
            ],
          },
        },
        displayNameRules: [
      v => !!v || 'Display name is required',
      v => v.length <= 50 || 'Display name must be less than 50 characters'
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
      };
    },
    computed: {
   
    },
    methods: {
      async create() {
        if (this.$refs.form.validate()) {
          await createUser({
            email: this.newItem.email,
            name: this.newItem.displayName,
            appLevel: this.newItem.customClaims.appLevel,
            role: this.newItem.customClaims.roles,
          });
          location.reload();
        }
      },
    },
  };
  </script>
  

  <style scoped>
.user-creation-card {
  max-width: 900px;
  margin: 0 auto;
}

.headline {
  font-size: 1.5rem !important;
}

.subtitle-1 {
  font-size: 1.1rem !important;
}
</style>