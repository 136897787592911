import axios from 'axios';

const updatePhoneNumber = async (phoneNum) => {
     const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/updateAdminPhoneNumber",{data:{
      phone : phoneNum,
     }
       
     })
     return result.data;
}

const resetPassword = async (email) => {
   const result = await axios.post("https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyDzJTSYxA2xp_ZSnTeQn9NOsxi8_XjecdA",{
      email : email,
      requestType : "PASSWORD_RESET"
   })
     return result.data;
}


const getAllUsers = async () => {
   const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getAllUsers")
     return result.data;
}


const getLogs = async () => {
   const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getLogs")
     return result.data;
}



const createUser = async (body) => {

   const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/createUser",body)
     return result.data;

}
const adminDashboardData = async () => {
   const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/adminDashboardData")
   return result.data;
}

const editUser = async (claim) => {

   const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/editUser",{
      claim : claim.customClaims,
      uid : claim.uid,
      name : claim.name,
      email : claim.email
   })
     return result.data;

}
export {updatePhoneNumber, resetPassword, getAllUsers, editUser,createUser, getLogs, adminDashboardData}