<template>
    <div class='ma-2'>
      <v-card-title>Users</v-card-title>
  
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
  
        <v-data-table
    :expanded.sync="expanded"
    item-key="row_id"
    v-model="selected"
    :loading="loading"
    loading-text="Loading... Please wait"
    multi-sort
    :sort-by="sortby"
    :sort-desc="sortDesc"
    
    :headers="headers"
    :items="users"
    show-expand
    :search="search"
    class="elevation-1"
  >
    <template v-slot:item.appLevel="{ item }">
      <v-chip-group active-class="primary--text" column>
        <v-chip
          v-for="tag in formatName(item.customClaims.appLevel)"
          :key="tag"
          small
          class="ma-1"
        >
          {{ tag }}
        </v-chip>
      </v-chip-group>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn icon small @click="editItem(item)">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="pa-5">
        <v-card flat>
          <v-card-title class="text-h6 font-weight-bold">
            Additional Details
          </v-card-title>
          <v-data-table
            :headers="expandedHeaders"
            :items="item.customClaims.roles"
            hide-default-footer
            class="elevation-0"
          >
            <template v-slot:item.appId="{ item: role }">
              {{ appName.find(a => a.id === role.appId).name }} ({{role.appId}})
            </template>
          </v-data-table>
        </v-card>
      </td>
    </template>
  </v-data-table>
      </v-card>
  
      <!-- Edit Modal -->
      <v-dialog v-model="dialog" max-width="900px">
    <v-card class="user-edit-card">
      <v-card-title class="headline primary white--text">
        {{ editedIndex === -1 ? 'New User' : 'Edit User' }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="editedItem.displayName"
                  label="Display Name"
                  outlined
                  dense
                  prepend-inner-icon="mdi-account"
                  :rules="[v => !!v || 'Display name is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="editedItem.email"
                  label="Email"
                  outlined
                  dense
                  prepend-inner-icon="mdi-email"
                  :rules="[
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>

            <h3 class="subtitle-1 font-weight-bold mb-2">Apps Access Level</h3>
            <v-row v-if="editedItem.customClaims">
              <v-col cols="12" sm="6" md="4" v-for="(app, index) in apps" :key="index">
                <v-checkbox
                  v-model="editedItem.customClaims.appLevel"
                  :label="app.name"
                  :value="app.value"
                  hide-details
                  class="mb-2"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>

            <h3 class="subtitle-1 font-weight-bold mb-2">Role Configuration</h3>
            <v-row v-if="editedItem.customClaims?.roles">
              <v-col cols="12" v-for="(r, i) in editedItem.customClaims.roles" :key="i">
                <v-card outlined class="pa-3 mb-3">
                  <p>{{appName.find(a => a.id === r.appId).name }}</p>
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="r.appId"
                        label="App ID"
                        disabled
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        :items='["Super_Admin", "Admin", "Program_Admin", "User"]'
                        v-model="r.role"
                        label="Role"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="r.title"
                        label="Title"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="r.org"
                        label="Organization"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="save" :disabled="!valid">
          {{ editedIndex === -1 ? 'Create' : 'Update' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
    </div>
  </template>

<script>
import moment from "moment";
import { getAllUsers,editUser } from "../dataservices/adminDataService";

export default {
  data() {
    return {
      rolesList : [
      {
                name : "Admin App",
                role: 'User',
                title: null,
                org: null,
                appId: 0,
              },
              {
                name : "OPDCS",
                role: 'User',
                title: null,
                org: null,
                appId: 1,
              },
              {
                name : "CATTS",
                role: 'User',
                title: null,
                org: null,
                appId: 2,
              },
              {
                name : "SPAR",
                role: 'User',
                title: null,
                org: null,
                appId: 3,
              },
              {
                name : "NGRI",
                role: 'User',
                title: null,
                org: null,
                appId: 4,
              },
              {
                name : "71.09",
                role: 'User',
                title: null,
                org: null,
                appId: 5,
              },
      ],
      expandedHeaders: [
        { text: 'App Id', value: 'appId' },
        { text: 'Role', value: 'role' },
        { text: 'Title', value: 'title' },
        { text: 'Org', value: 'org' },
      ],

      editedItem: {
      displayName: '',
      email: '',
      customClaims: {
        appLevel: [],
        roles: []
      }
    },
      valid: true,
      apps: [
      { name: 'Admin App', value: 0 },
      { name: 'OPDCS', value: 1 },
      { name: 'CATTS', value: 2 },
      { name: 'SPAR', value: 3 },
      { name: 'NGRI', value: 4 },
      { name: '71.09', value: 5 }
    ],
      appName: [
        { id: 0, name: "Admin Portal" },
        { id: 1, name: "OPDCS" },
        { id: 2, name: "CATTS" },
        { id: 3, name: "SPAR" },
        { id: 4, name: "NGRI" },
        { id: 5, name: "71.09" },
      ],
      expanded: [],
      vendors: [],
      dialog: false,
      selected: [],
      loading: true,
      sortby: [],
      sortDesc: [],
      headers: [
        { text: "Uid", align: "start", sortable: true, value: "uid" },
        { text: "Display Name", align: "start", sortable: true, value: "displayName" },
        { text: "Email", align: "start", sortable: true, value: "email" },
        { text: "Creation Time", align: "start", sortable: true, value: "metadata.creationTime" },
        { text: "Last Sign In Time", align: "start", sortable: true, value: "metadata.lastSignInTime" },
        { text: "Have Access to", align: "start", sortable: true, value: "appLevel" },
        { text: "Actions", value: "actions", sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      search: null,
      users: [],
      editedIndex: -1,
    };
  },
  async mounted() {
    this.users = await getAllUsers();
    this.loading = false;
  },
  methods: {

    combineObjects(firstObj, secondObj) {
  // Create a map of the first object for easier lookup
  const firstObjMap = new Map(firstObj.map(item => [item.appId, item]));
  
  // Start with all items from the second object
  const result = secondObj.map(item => {
    const matchingItem = firstObjMap.get(item.appId);
    if (matchingItem) {
      // If there's a matching item in the first object, merge them
      return {
        ...item,           // Start with all properties from the second object
        ...matchingItem,   // Overwrite with properties from the first object
        name: item.name    // Ensure we keep the 'name' property from the second object
      };
    }
    // If no matching item, return the item from the second object as is
    return item;
  });
  
  // Add any items from the first object that weren't in the second object
  firstObj.forEach(item => {
    if (!result.some(r => r.appId === item.appId)) {
      result.push(item);
    }
  });
  
  return result;
},
    formatName(appIds) {
      return appIds.map(a => this.appName.find(app => app.id === a).name);
    },
    goToListAssoicatedContractsPage(item) {
      this.$router.push("contract/" + item.id);
    },
    formatdateCreated(dateCreated) {
      return moment(dateCreated).fromNow();
    },
  
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      const combinedObjects = this.combineObjects(this.editedItem.customClaims.roles,this.rolesList)
    

console.log(combinedObjects);


      this.editedItem.customClaims.roles = combinedObjects;
      this.dialog = true;
    },
    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
      } else {
        this.items.push(this.editedItem);
      }
      await editUser(this.editedItem);
      this.close();
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    addRole() {
      this.editedItem.customClaims.roles.push({
        appId: null,
        role: '',
        title: '',
        org: ''
      });
    },
    removeRole(index) {
      this.editedItem.customClaims.roles.splice(index, 1);
    }
  },
};
</script>
<style scoped>
.v-data-table ::v-deep tbody tr:hover {
  background-color: #f5f5f5;
}

.v-data-table ::v-deep .v-data-table__expanded__content {
  box-shadow: inset 0 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0 -4px 8px -4px rgba(0, 0, 0, 0.1);
}

.v-card {
  transition: all 0.3s ease-in-out;
}



.user-edit-card {
  max-width: 900px;
  margin: 0 auto;
}

.headline {
  font-size: 1.5rem !important;
}

.subtitle-1 {
  font-size: 1.1rem !important;
}
</style>